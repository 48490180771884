<!--
* @description:
* @fileName shopEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <div class="demo-drawer__content">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="店铺名称" prop="shopName" :label-width="90">
          <el-input
            v-model="form.shopName"
            autocomplete="off"
            maxlength="50"
          ></el-input>
        </el-form-item>

        <el-form-item label="排序值" prop="title" :label-width="80">
          <el-input-number
            v-model="form.sort"
            :min="1"
            label="请选择排序值"
          ></el-input-number>
        </el-form-item>

        <el-form-item
          label="营业时间"
          prop="shopBusinessTime"
          :label-width="90"
        >
          <el-input
            v-model="form.shopBusinessTime"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="省市区" prop="address" :label-width="90">
          <el-cascader
            :props="optionsLevel"
            v-model="form.address"
            v-if="isShowInfo"
            style="width: 250px"
          />
        </el-form-item>

        <el-form-item label="地图选址" prop="addressByMap" :label-width="90">
          <el-button
            :type="!form.shopLatitude ? 'info' : 'success'"
            @click="chooseMapPlace"
          >
            {{ !form.shopLatitude ? '选择' : '已选择' }}
          </el-button>
          <!-- <el-tag v-if="form.poiname" style="margin-left: 10px">
            {{ form.poiname }}
          </el-tag> -->
        </el-form-item>

        <el-form-item label="详细地址" prop="shopAddress" :label-width="90">
          <el-input v-model="form.shopAddress" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="联系电话1"
          prop="shopContactNumber"
          :label-width="90"
        >
          <el-input
            v-model="form.shopContactNumber"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话2" prop="shopTelephone" :label-width="90">
          <el-input v-model="form.shopTelephone" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="封面图片" prop="coverUrl" :label-width="90">
          <el-upload
            class="upload-demo"
            action="#"
            list-type="picture-card"
            :limit="1"
            multiple
            :file-list="form.coverList"
            :http-request="upCover"
            :on-error="handleError"
            :on-exceed="handleExceed2"
            :on-preview="handlePreview"
            :on-remove="handleRemove2"
            accept=".png,.jpeg,.jpg"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              <em>点击上传图片</em>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="店铺logo" prop="shopLogo" :label-width="90">
          <el-upload
            class="upload-demo"
            action="#"
            list-type="picture-card"
            :limit="1"
            multiple
            :file-list="form.logoList"
            :http-request="upLogo"
            :on-error="handleError"
            :on-exceed="handleExceed1"
            :on-preview="handlePreview"
            :on-remove="handleRemove1"
            accept=".png,.jpeg,.jpg"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              <em>点击上传图片</em>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="店铺图片" prop="bannerUrl" :label-width="90">
          <el-upload
            class="upload-demo"
            action="#"
            list-type="picture-card"
            :limit="limit"
            multiple
            :file-list="nowImgList"
            :http-request="upBannar"
            :on-error="handleError"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            accept=".png,.jpeg,.jpg"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              <em>点击上传图片</em>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="店铺标签" :label-width="90">
          <el-select
            v-model="form.labelIds"
            placeholder="请选择店铺标签"
            multiple
          >
            <template v-for="(item, index) in tagOption" :key="index">
              <el-option :label="item.label" :value="item.data"></el-option>
            </template>
          </el-select>
          <el-button
            type="success"
            :icon="Plus"
            @click="addShopTag"
            style="margin-left: 20px"
          ></el-button>
        </el-form-item>

        <el-form-item
          label="优惠券"
          :label-width="90"
          v-if="title == '编辑店铺'"
        >
          <el-select
            v-model="form.couponCodeList"
            multiple
            placeholder="请选择优惠券"
          >
            <template v-for="(item, index) in couponList" :key="index">
              <el-option
                :label="item.couponName"
                :value="item.couponCode"
              ></el-option>
            </template>
          </el-select>
          <el-button
            type="success"
            :icon="Plus"
            @click="addTicket"
            style="margin-left: 20px"
          ></el-button>
        </el-form-item>
        <el-form-item label="店铺简介" prop="shopProfile" :label-width="90">
          <vab-quill
            v-model="form.shopProfile"
            :min-height="400"
            :options="Richoptions"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submitInfo()">提交</el-button>
    </div>

    <vab-upload
      ref="vabUploadRef"
      :limit="1"
      name="file"
      :size="2"
      url="/upload"
      @addImg="handleAddImg"
    />

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

    <Tmap ref="mapRef" @choose-place="choosePlace"></Tmap>
    <edit ref="editRef" @fetch-data="getShopTag" />
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { upLoadImg } from '@/api/active'

  import { isPhone } from '@/utils/validate'
  import { Plus } from '@element-plus/icons'
  import {
    getProvinceInfo,
    getCityInfo,
    getAreaInfo,
    addShop,
    editShop,
  } from '@/api/shop'

  import { getLabelLabel } from '@/api/active'
  import { getCouponList } from '@/api/ticket'
  import { parseTime } from '@/utils/index'
  import VabQuill from '@/extra/VabQuill'
  import VabUpload from '@/extra/VabUpload'
  import Tmap from '@/components/Tmap/Tmap'

  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    name: 'shopEdit',
    components: {
      VabQuill,
      VabUpload,
      Tmap,
      Edit: defineAsyncComponent(() =>
        import('../../active/components/tagEdit')
      ),
    },
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const router = useRouter()
      const { proxy } = getCurrentInstance()

      // 自定义手机验证
      const validatePhone = (rule, value, callback) => {
        const regExp =
          /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
        if (!regExp.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }

      const validateMap = (rule, value, callback) => {
        if (state.form.shopLatitude) {
          callback()
        } else {
          callback(new Error('请选择地址'))
        }
      }

      const validateBannar = (rule, value, callback) => {
        if (state.nowImgList.length > 0) {
          callback()
        } else {
          callback(new Error('请上传店铺图片'))
        }
      }

      const validateProfile = (rule, value, callback) => {
        if (state.form.shopProfile.length > 0) {
          callback()
        } else {
          callback(new Error('请填写店铺简介'))
        }
      }

      const state = reactive({
        dialogVisible: false,
        dialogImageUrl: '',
        formRef: null,
        mapRef: null,
        editRef: null,
        editCouponRef: null,
        vabUploadRef: null,
        isShowInfo: false,
        checkMenu: [],
        tagOption: [],
        couponList: [],
        size: '50%',
        address: [],
        form: {
          description: '',
          shopLatitude: null,
          shopLongitude: null,
          couponCodeList: [123],
          imgUrlList: [],
          shopLogo: '',
          sort: 1,
        },
        nowImgList: [],
        rules: {
          shopName: [
            { required: true, trigger: 'blur', message: '请输入店铺名称' },
          ],
          address: [
            {
              required: true,
              trigger: 'change',
              message: '请输入店铺所在省市区',
            },
          ],
          addressByMap: [
            { required: true, trigger: 'change', validator: validateMap },
          ],
          shopContactNumber: [
            { required: true, trigger: 'blur', message: '请输入联系电话' },
          ],
          shopLogo: [
            { required: true, trigger: 'change', message: '请选择店铺logo' },
          ],
          coverUrl: [
            { required: true, trigger: 'change', message: '请选择封面图片' },
          ],
          bannerUrl: [
            { required: true, trigger: 'change', validator: validateBannar },
          ],
          shopBusinessTime: [
            { required: true, trigger: 'change', message: '请输入营业时间' },
          ],
          shopAddress: [
            { required: true, trigger: 'blur', message: '请输入详细地址' },
          ],
          shopProfile: [
            { required: true, trigger: 'change', validator: validateProfile },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        pickerStartDate: null,
        limit: 8,
        Richoptions: {
          theme: 'snow',
          bounds: document.body,
          debug: 'warn',
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                [{ color: [] }, { background: [] }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ align: [] }],
                [{ direction: 'rtl' }],
                [{ font: [] }],
                ['clean'],
                ['image'],
              ],
              handlers: {
                image: () => {
                  state['vabUploadRef'].handleShow()
                },
              },
            },
          },
        },
        provinceInfo: [],
      })

      const optionsLevel = {
        lazy: true,
        value: 'name',
        label: 'name',
        async lazyLoad(node, resolve) {
          const { level } = node
          const { code } = node.data
          if (level == 0) {
            const { data } = await getProvinceInfo()
            resolve(data)
          } else if (level == 1) {
            const { data } = await getCityInfo({ code: code })
            resolve(data)
          } else {
            const { data } = await getAreaInfo({ code: code })
            data.forEach((v, i) => {
              v.leaf = level >= 2
            })
            resolve(data)
          }
        },
      }
      const showEdit = async (row) => {
        state.isShowInfo = false
        setTimeout(() => {
          state.isShowInfo = true
        })
        if (!row) {
          state.title = '添加店铺'
          // state['formRef'].resetFields()
          state.form = {}
          state.form.shopProfile = ''
          state.form.logoList = []
          state.nowImgList = []
        } else {
          state.title = '编辑店铺'
          // 数据回显
          row.logoList = [{ name: 'logo', url: row.shopLogo }]
          row.coverList = [{ name: 'cover', url: row.coverUrl }]

          await fetchData()
          state.nowImgList = handleImgArr(row.imgUrlList)
          state.form = Object.assign({}, row)
          state.form.address = [row.shopProvince, row.shopCity, row.shopArea]

          console.log(state.form)
          getCoupon(row)
        }
        state.dialogFormVisible = true

        getShopTag()
      }

      const handleImgArr = (arr) => {
        var _arr = []
        for (let i in arr) {
          let obj = {
            name: 'logo',
            url: arr[i],
          }
          _arr.push(obj)
        }
        return _arr
      }
      // const handleData = (arr, checkArr) => {
      //   for (let i in arr) {
      //     arr[i] = String(arr[i])
      //   }
      //   return arr
      // }

      const handleClose = () => {
        state['formRef'].resetFields()
        state.form = {}
        state.form.shopProfile = ''
        state.form.logoList = []
        state.form.address = []
        state.nowImgList = []
        state.dialogFormVisible = false
      }

      const fetchData = async () => {
        const { data: provinceInfo } = await getProvinceInfo()
        state.provinceInfo = provinceInfo
      }

      // 地图选点
      const choosePlace = (e) => {
        console.log(e)
        state.form.shopLatitude = e.latlng.lat
        state.form.shopLongitude = e.latlng.lng
        state.form.poiname = e.poiname
      }

      onMounted(() => {
        fetchData()
      })
      // 图片相关接口
      // 上传海报
      const upBannar = async (e) => {
        console.log(e)
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        let obj = {
          name: e.file.name,
          uid: e.file.uid,
          url: data,
        }
        state.nowImgList.push(obj)
      }

      // 上传海报
      const upLogo = async (e) => {
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.shopLogo = data
      }

      // 上传cover
      const upCover = async (e) => {
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.coverUrl = data
      }

      const handleError = (err, file) => {
        proxy.$baseMessage(
          `文件[${file.raw.name}]上传失败,文件大小为${_.round(
            file.raw.size / 1024,
            0
          )}KB`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed = (files) => {
        proxy.$baseMessage(
          `当前限制选择 ${proxy.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed1 = (files) => {
        proxy.$baseMessage(
          `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove = (file, fileList) => {
        console.log(file, state.nowImgList)
        for (let i in state.nowImgList) {
          if (state.form.imgUrlList[i].uid) {
            state.nowImgList[i].uid == file.raw.uid
              ? state.nowImgList.splice(i, 1)
              : ''
          } else {
            state.nowImgList[i].url == file.url
              ? state.nowImgList.splice(i, 1)
              : ''
          }
        }
        console.log(state.nowImgList)
      }

      const handleRemove1 = (file, fileList) => {
        state.form.shopLogo = ''
      }

      const handleExceed2 = (files) => {
        proxy.$baseMessage(
          `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove2 = (file, fileList) => {
        state.form.coverUrl = ''
      }

      const handlePreview = (file) => {
        state.dialogImageUrl = file.url
        state.dialogVisible = true
      }

      // 富文本上传图片
      const handleAddImg = async (e) => {
        state.form.shopProfile += `<img src="${e}" />`
      }
      const submitInfo = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            state.form.imgUrlList = state.nowImgList.map((item) => {
              return item.url
            })
            state.form.shopProvince = state.form.address[0]
            state.form.shopCity = state.form.address[1]
            state.form.shopArea = state.form.address[2]
            console.log(state.form)
            if (state.title == '编辑店铺') {
              let data = await editShop(state.form)
              msg = data.msg
            } else {
              let data = await addShop(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            handleClose()
          }
        })
      }

      // 地图选点
      const chooseMapPlace = () => {
        state.mapRef.showDialog()
      }
      // 获取店铺标签
      const getShopTag = async () => {
        const { data } = await getLabelLabel({}, 'SHOP')
        state.tagOption = data
      }

      // 新增店铺标签
      const addShopTag = async (e) => {
        console.log(state.editRef)
        state.editRef.showEdit(null, 'shop')
      }

      // 新增优惠券
      const addTicket = async (e) => {
        let url = state.form.id
          ? `/ticket/ticketList?shopId=${state.form.id}`
          : '/ticket/ticketList'
        router.push(url)
      }

      const getCoupon = async (row) => {
        const { data } = await getCouponList({ shopId: row.id })
        state.couponList = data
      }
      return {
        ...toRefs(state),
        showEdit,
        handleClose,
        upBannar,
        upLogo,
        fetchData,
        handleAddImg,
        submitInfo,
        handleError,
        handleExceed,
        handleExceed1,
        handleRemove,
        handleRemove1,
        handlePreview,
        choosePlace,
        chooseMapPlace,
        addShopTag,
        getShopTag,
        addTicket,
        optionsLevel,
        Plus,
        upCover,
        handleExceed2,
        handleRemove2,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
